

export const HomeDetail = (props) => {
    return (
      <div id='homeDetail'>
        <h1 className='text-center'>Welcome to Kiddie Star Home-based ECE</h1>
        <div className='container'>
            <div className='row'>

                <div className='col-xs-12 col-md-6'>
                    <p className="phraseOne">{props.data ? props.data.paragraph : 'loading...'}</p>
                </div>

                <div className='col-xs-12 col-md-6'>
                    {' '}
                    <img src='img/home/adam-winger.jpg' className='img-responsive' alt='' />{' '}
                </div>
            </div>

            <div className='special'>
                {/* <p className='lead text-warning'><strong>{props.data ? props.data.subTitle3 : 'loading...'}</strong></p> */}
                <p className='lead'><strong>{props.data ? props.data.subTitle3 : 'loading...'}</strong></p>
                <div className='list-style'>
                    <ul>
                        {props.data ? props.data.points3.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                            ))
                        : 'loading'}
                    </ul>
                </div>
            </div>

            <div className='row'>
                <div className='col-xs-12 col-md-6'>
                    <div className='normal'>
                        <p className='lead'><strong>{props.data ? props.data.subTitle1 : 'loading...'}</strong></p>
                        <div className='list-style'>
                            <ul>
                                {props.data ? props.data.points1.map((d, i) => (
                                    <li key={`${d}-${i}`}>{d}</li>
                                    ))
                                : 'loading'}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xs-12 col-md-6'>
                    {' '}
                    <img src='img/For Parents.jpg' className='img-responsive' alt='' />{' '}
                </div>
            </div>
            
            <div className='row'>
                <div className='col-xs-12 col-md-6'>
                    {' '}
                    <img src='img/For Educator.jpg' className='img-responsive' alt='' />{' '}
                </div>

                <div className='col-xs-12 col-md-6'>
                    <div className='normal'>
                        <p className='lead'><strong>{props.data ? props.data.subTitle2 : 'loading...'}</strong></p>
                        <div className='list-style'>
                            <ul>
                                {props.data ? props.data.points2.map((d, i) => (
                                    <li key={`${d}-${i}`}>{d}</li>
                                    ))
                                : 'loading'}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
    )
  }
  