import { Link } from 'react-router-dom';


const routes = [
  { href: "/", label: "Home" },
  { href: "/about", label: "Our Philosophy" },
  // { href: "/t", label: "Our Process" },
  // { href: "/events", label: "Events" },
  // { href: "/a", label: "Gallery" },
  // { href: "/", label: "Gallery" },
  // { href: "/contact", label: "Contact" }
  // ,    { href: "/quoteNow", label: "Quote" }
];


export const Navigation = (props) => {

  let pathname = window.location.pathname;
  // console.log(pathname);

  return (
    <div id='navbarTotal'>
      <div id="paddingDiv"><p>The Homebase Childcare Specialist for children from 3 months to 5 years old.</p></div> 
      <nav id='menu' className='navbar navbar-default '>  
      {/* navbar-fixed-top */}
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            {/* <a className='navbar-brand page-scroll' href='#page-top'>
              React Landing Page
            </a>{' '} */}
            <Link to="/"  className="relative ">
              <img src='img/logo.png' height="66" alt="" />
            </Link>
          </div>

          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>

              <li >
                <Link to="/" className={pathname==='/' ? "activeli" : ""}>
                  <span >Home</span>
                </Link>
              </li>

              <li>
                <Link to="/about" className={pathname==='/about' ? "activeli" : ""}>
                  <span>Our Philosophy</span>
                </Link>
              </li>

              <li>
                <Link to="/forEducators" className={pathname==='/forEducators' ? "activeli" : ""}>
                  <span>For Educators</span>
                </Link>
              </li>

              <li>
                <Link to="/forParents" className={pathname==='/forParents' ? "activeli" : ""}>
                  <span>For Parents</span>
                </Link>
              </li>

              <li>
                <Link to="/contact" className={pathname==='/contact' ? "activeli" : ""}>
                  <span>Contact</span>
                </Link>
              </li>

              <li>
                <a href="https://www.discoverchildcare.co.nz/e9eaa376-5e50-4f29-b056-b47448d1ed78/widget/enrolment?centreToken=e9eaa376-5e50-4f29-b056-b47448d1ed78">
                  <span>Enrollment</span>
                </a>
              </li>

              {/* <li>
                <a href='#features' className='page-scroll'>
                  Features
                </a>
              </li>
              <li>
                <a href='#about' className='page-scroll'>
                  About
                </a> 
              </li>
              <li>
                <a href='#services' className='page-scroll'>
                  Services
                </a>
              </li>
              <li>
                <a href='#portfolio' className='page-scroll'>
                  Gallery
                </a>
              </li>
              <li>
                <a href='#testimonials' className='page-scroll'>
                  Testimonials
                </a>
              </li>
              <li>
                <a href='#team' className='page-scroll'>
                  Team
                </a>
              </li>
              <li>
                <Link to="/contact" className="">
                  <span>Contact</span>
                </Link>
                <a href='#contact' className='page-scroll'>
                  Contact
                </a> 
              </li> */}
            </ul>
          </div>
        </div>

      </nav>
    </div>
  )
}
