import { Navigation } from '.././components/navigation'
import { About } from '.././components/about'
import JsonData from '.././data/data.json'
import { Footer } from '.././components//footer'
import { TwoImgs } from '.././components/twoImgs'

export const AboutUs = (props) => {
    return (
        <div>
            
        <Navigation />
        {/* <div id="paddingDiv"></div> */}
        {/* <About data={JsonData.About} /> */}
        {/* <section id="one" className="wow fadeInUp"> */}
            <div id='about'>
                <div className="container">
                    <h2>The Kiddie Star Philosophy:</h2>
                    {/* <p className="lead">
                        What you notice first in <a href="https://v4-alpha.getbootstrap.com/">Bootstrap 4</a> is that <code>.panel</code> and <code>.well</code> have been replaced by the <code>.card</code>. This makes a lot of sense as "cards" are one of the more well known trends in responsive design.
                        The <a href="">Bootstrap 4 Cards</a> can be singular, grouped together (as shown) or <a href="">equal height</a>. There are many different layout and content options.
                    </p> */}
                    <p className="lead">Kiddie Star Homebased Childcare’s philosophy is guided by the Te Whariki, New Zealand Early Childhood Education Curriculum and biculturalism principles.</p>
                    {/* <div className='pad'></div> */}
                    <div className="text-center">
                        <img src='img/Philosophy page.jpg' className='rounded' alt='' />{' '}
                    </div>
                    <div className='row' >
                        <div className='col-xs-12 col-md-6'>
                            <h3 className="text-success">Infant and Toddlers (0 to 2 years old)</h3>
                            <p className="lead">Our coordinator and educator who in care of the infants and toddlers are inspired by Emmi Pikler and Magda Gerbers RIE approach.</p>
                            {/* <div className='list-style'> */}
                            <div className='about-text'>
                                <ul>
                                    {JsonData.About
                                    ? JsonData.About.Why3.map((d, i) => (
                                        <li key={`${d}-${i}`}> <b>{d.title}</b> {d.text}</li>
                                        ))
                                    : 'loading'}
                                </ul>
                            </div>
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <h3 className="text-success">Toddler and Pre-schooler (2 to 5 years old)</h3>
                            <p className="lead">Our Kiddie Star teaching philosophy for Toddlers and Pre-schoolers are:</p>
                            {/* <div className='list-style'> */}
                            <div className='about-text'>
                                <ul>
                                    {JsonData.About
                                    ? JsonData.About.Why4.map((d, i) => (
                                        <li key={`${d}-${i}`}> <b>{d.title}</b> {d.text}</li>
                                        ))
                                    : 'loading'}
                                </ul>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        {/* </section> */}
        <TwoImgs />
        <Footer />
    </div>
    )
  }
  