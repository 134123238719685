export const Footer = (props) => {
    return (
    <div id='footer'>
        
        <div className='container text-center'>
          <img src='img/brands.jpg' className='img-responsive' alt='' />{' '}
          <p>
            &copy; All Rights Reserved by {' '} © 2018.
            <a href='http://www.kiddiestar.co.nz/' rel='nofollow'>
              Kiddie
            </a>
          </p>
        </div>
    </div>
    )
  }
  