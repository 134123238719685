import { useState, useEffect } from 'react'
import { Navigation } from '.././components/navigation'
import { Header } from '.././components/header'
// import { Features } from '.././components/features'
// import { About } from '.././components/about'
// import { Services } from '.././components/services'
// import { Gallery } from '.././components/gallery'
// import { Testimonials } from '.././components/testimonials'
// import { Team } from '.././components/Team'
// import { Contact } from '.././components/contact'
import { HomeDetail } from '.././components/homeDetail'
import JsonData from '.././data/data.json'
import SmoothScroll from 'smooth-scroll'
import { Footer } from '.././components/footer'
import { TwoImgs } from '.././components/twoImgs'
// import flag from '../../public/video.mp4';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

export const Home = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    
    <div>
        <Navigation />
        <div id="showflags"/>
        <Header data={landingPageData.Header} />    
        <HomeDetail data={landingPageData.HomeDetail} />
        {/* <Features data={landingPageData.Features} />
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Gallery />
        <Testimonials data={landingPageData.Testimonials} />
        <Team data={landingPageData.Team} />
        <Contact data={landingPageData.Contact} /> */}
        <TwoImgs />
        <Footer />
    </div>
  )
}

// export default Home
