import { useState, useEffect } from 'react'
// import { Navigation } from './components/navigation'
// import { Header } from './components/header'
// import { Features } from './components/features'
// import { About } from './components/about'
// import { Services } from './components/services'
// import { Gallery } from './components/gallery'
// import { Testimonials } from './components/testimonials'
// import { Team } from './components/Team'
// import { Contact } from './components/contact'
// import { Footer } from './components/footer'
// import { TwoImgs } from './components/twoImgs'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'


import {
  Switch,
  Route
} from 'react-router-dom';
import { AboutUs } from './pages/AboutUs'
import { Home } from './pages/Home'
import { ContactUs } from './pages/ContactUs'
import { ForParents } from './pages/ForParents'
import { ForEducators } from './pages/ForEducators'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    
    <div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <AboutUs />
        </Route>
        <Route path="/forEducators">
          <ForEducators />
        </Route>
        <Route path="/forParents">
          <ForParents />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
      </Switch>


      {/* <TwoImgs /> */}
      
      
      {/*<Navigation />
      <Header data={landingPageData.Header} />      
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} /> */}
    </div>
    
  )
}

export default App
