import { Navigation } from '.././components/navigation'
// import JsonData from '.././data/data.json'
// import { Testimonials } from '.././components/testimonials'
import { Footer } from '.././components//footer'
import { TwoImgs } from '.././components/twoImgs'

export const ForParents = (props) => {
    return (
        <div>
            <Navigation />
            {/* <Testimonials data={JsonData.Testimonials} /> */}

            <div id='about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xs-12 col-md-6'>
                            {' '}
                            <img src='img/josh-applegate.jpg' className='img-responsive' alt='' />{' '}
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <div className='FP-text'>
                                <h2>In-home flexible childcare</h2>
                                <p>Book part-time or full-time childcare with our educator today.
                                    Kiddie Star Home based childcare’s vision is to provide comprehensive and advance curriculum, partnering the parents, 
                                    Whanau and caregivers where we empower our children to thrive. We are licensed by the Ministry of Education.
                                </p>
                                <p>At Kiddie Star, Our in-home Educators provide full and part day education and childcare in their own homes for up to 4 preschool children at any one time, with only 2 children under the age of two.  
                                    Small ratios mean your child receives individualised care in a nurturing, educational and fun home environment. 
                                    Regular outings and playgroups offered by Kiddie Star Homebased provide your child the opportunity to experience larger group interactions while having the security of their familiar Educator.
                                </p>
                                <p>At Kiddie star, we strive to choose an educator that most suitable for you and your child. 
                                    At the same time, all the educators are guided and mentored by an experience fully registered visiting teacher, to ensure all the practices, education and cares are professional.
                                </p>
                                <p className="text-warning">Choose Kiddie Star who is seeding their love to plant  your child’s future. </p>
                                <p className="vcenter"> Contact us today via email:  <a href = "mailto: info@kiddie.co.nz">welcome@kiddiestar.co.nz</a><br/>or give us a call: 0222746668</p>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <TwoImgs />
            <Footer />
        </div>
    )
  }
  