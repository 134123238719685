import { Navigation } from '.././components/navigation'
import { Contact } from '.././components/contact'
import JsonData from '.././data/data.json'
import { Footer } from'.././components/footer'


export const ContactUs = (props) => {
    return (
        <div>
            <Navigation />
            {/* <Contact data={JsonData.Contact} /> */}
            <div id='contact'>
                <div className='container'>
                    {/* <div className='row '>
                        <div className='col-xs-12 col-md-6'>
                            {' '}
                            <img src='img/Kids-girl-pencil.jpg' className='img-responsive' alt='' />{' '}
                        </div>

                        <div className='col-xs-12 col-md-6'>
                            {' '}
                            <img src='img/phong-duong.jpg' className='img-responsive float-right' alt='' />{' '}
                        </div>
                    </div> */}
                    <div className='contactPageImages'>
                        
                            <img src='img/Kids-girl-pencil.jpg'  alt='' />{' '}
                        

                        
                            <img src='img/phong-duong.jpg'  alt='' />{' '}
                        
                    </div>

                    {/* <p className=" vcenter"><strong><em> {JsonData.Contact ? JsonData.Contact.text1 : 'loading'}</em></strong></p> */}
                    <p className="vcenter"> {JsonData.Contact ? JsonData.Contact.text1 : 'loading'}</p>
                    <p className="vcenter"> {JsonData.Contact ? JsonData.Contact.text2 : 'loading'}</p>
                    <p className="vcenter"> Contact us today via email:  <a href = "mailto: welcome@kiddiestar.co.nz">welcome@kiddiestar.co.nz</a> Or  <a href = "mailto: sylvia@kiddiestar.co.nz">sylvia@kiddiestar.co.nz</a>
                    <br/>or give us a call: <a>0222746668</a> 
                    <br/>or add our wechat: <a>KiddieStar</a>
                    <br/>
                    <br/><button className="btn btn-info btn-lg"> <a href = "https://www.discoverchildcare.co.nz/e9eaa376-5e50-4f29-b056-b47448d1ed78/widget/enquiry?centreToken=e9eaa376-5e50-4f29-b056-b47448d1ed78">
                        click HERE to enquiry NOW</a></button>
                    
                    </p>

                    
                

                </div>
            </div>

            <Footer />
        </div>
    )
  }
  