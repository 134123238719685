import { Navigation } from '.././components/navigation'
import { Team } from '.././components/Team'
import JsonData from '.././data/data.json'
import { Footer } from '.././components//footer'
import { TwoImgs } from '.././components/twoImgs'

export const ForEducators = (props) => {
    return (
        <div>
            <Navigation/>
            {/* <Team data={JsonData.Team} /> */}
            <div id='about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xs-12 col-md-6'>
                            {' '}
                            <img src='img/forEdu.jpg' className='img-responsive' alt='' />{' '}
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <div className='ForEducators'>
                                <h2>Join the professional team!</h2>
                                <h3>Do you love children? Are you liking the flexible working hours?</h3>
                                <h3>Contact us today!</h3>
                                <p className="text-warning"><strong><em>{JsonData.ForEducators? JsonData.ForEducators.subTitle : 'loading...'}</em></strong></p>
                                <div className='list-style'>
                                    <ul>
                                        {JsonData.ForEducators
                                        ? JsonData.ForEducators.points.map((d, i) => (
                                            <li key={`${d}-${i}`}>{d}</li>
                                            ))
                                        : 'loading'}
                                    </ul>
                                </div>
                                <p className="text-warning"><strong><em>{JsonData.ForEducators? JsonData.ForEducators.subTitle2 : 'loading...'}</em></strong></p>
                                <div className='list-style'>
                                    <ul>
                                        {JsonData.ForEducators
                                        ? JsonData.ForEducators.points2.map((d, i) => (
                                            <li key={`${d}-${i}`}>{d}</li> 
                                            ))
                                        : 'loading'}
                                    </ul>
                                </div>
                                <p className="vcenter"> Contact us today via email:  <a href = "mailto: info@kiddie.co.nz">welcome@kiddiestar.co.nz</a><br/>or give us a call: 0222746668</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TwoImgs />
            <Footer />
        </div>
    )
  }
  