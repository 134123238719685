import { Link } from 'react-router-dom';

export const TwoImgs = (props) => {
    return (
        <div id='TwoImgs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xs-12 col-md-6 left'>
                        <img src='img/about4.jpg' className='img-responsive' alt='' />
                        <div className="text-block">
                            <h4>Educators</h4>
                            <p className='middle'>Become an in-home Educator with Kiddie Star and start doing what you love!
                            <br/><br/>
                            Contact us today via email: welcome@kiddiestar.co.nz
                            </p>
                            
                            {/* <a href="https://www.discoverchildcare.co.nz/e9eaa376-5e50-4f29-b056-b47448d1ed78/widget/enrolment?centreToken=e9eaa376-5e50-4f29-b056-b47448d1ed78" className="btn btn-custom-v2">
                                Enroll today
                            </a> */}
                            {/* <Link to="/forEducators" className="btn btn-custom-v2">
                                Register Now
                            </Link> */}
                        </div>
                    </div>
                    <div className='col-xs-12 col-md-6 right'>
                        <img src='img/about.jpg' className='img-responsive' alt='' />
                        <div className="sometext">
                            <h4>Parents</h4>
                            <p className='middle'>Choose Kiddie Star if you’re looking for a nurturing, educational & fun environment for your child.</p>
                            <a href="https://www.discoverchildcare.co.nz/e9eaa376-5e50-4f29-b056-b47448d1ed78/widget/enrolment?centreToken=e9eaa376-5e50-4f29-b056-b47448d1ed78" className='btn btn-custom-v3'>
                                Enroll today
                            </a>
                            {/* <Link to="/forParents" className="">
                                <p className='btn btn-custom-v3'>
                                Enrol Now
                                </p>{' '}
                            </Link> */}
                        </div>
                    </div>
                </div>
                {/* <div className='addMargin'></div> */}
            </div>
        </div>
    )
  }
  